<script setup>
import  { gsap } from "gsap";

import Lenis from 'lenis'

const lenis = ref(null)

onMounted(() => {
	lenis.value = new Lenis({
		smoothWheel: true,
		duration: 3
	});

	gsap.ticker.add((time) => {
		lenis.value.raf(time * 1000);
	});

	gsap.ticker.lagSmoothing(0);
})

</script>

<template>
	<div>
		<Loader />
		<AppHeader />
		<slot />
		<AppFooter />
	</div>
</template>