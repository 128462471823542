<script setup>

const { $listen } = useNuxtApp()
const loaded = ref(false)

$listen('pageLoaded', (finished) => {
	if (finished) {
		loaded.value = true
	}
})

</script>

<template>
	<div class="fixed top-0 w-screen h-screen bg-white z-[1000] flex justify-center items-center transition-all ease-out duration-[750ms]" :class="{'translate-x-full' : loaded}">
		<svgo-bea class="w-[65vw] text-dark loader" :fontControlled="false" />
	</div>
</template>

<style lang="scss" scoped>

.loader {
	animation: pulse 2s infinite;
}


@keyframes pulse {
	0% {
			transform: scale(1);
			opacity: 0.75;
	}

	50% {
			transform: scale(1.05);
			opacity: 1;
	}

	100% {
			transform: scale(1);
			opacity: 0.75;
	}
}

</style>