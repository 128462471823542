<script setup>

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

onMounted(() => {

	gsap.to(".cover", {
		scrollTrigger: {
			trigger: 'footer',
			start: 'top bottom',
		},
		x: '100%',
		duration: 1
	});
})

</script>

<template>
	<footer class="bg-dark text-white overflow-hidden">                
		<div class="container">
			<div class="pt-10 md:pt-s90 pb-5 md:px-s60">
				<div class="relative">
					<div class="cover absolute w-full h-full bg-dark"></div>
					<svgo-bea class="w-full text-turquoise mb-s90" :fontControlled="false" />
				</div>

				<div class="hidden md:flex justify-between mb-s60">
					<div class="col-span-3">
						<div><a href="mailto:info@be-a.cz" class="link text-lg">info@be-a.cz</a></div>
					</div>
					<div class="col-span-3">
						<p class="text-lg">Pujmanové 1753/10a,<br> 140 00 Praha 4-Nusle</p>
					</div>
					<div class="col-span-3">
					</div>
					<div class="col-span-3 text-right">
						<div><a href="https://www.instagram.com/amazing_be_a?igsh=eWt2czh5c2RoZXNz" target="_blank" class="link text-lg">Instagram</a></div>
						<div><a href="https://www.linkedin.com/in/filip-linek-a70292318/" target="_blank" class="link text-lg">Linkedin</a></div>
					</div>
				</div>
				<div class="md:hidden flex justify-between mb-s30">
					<div>
						<div><a href="mailto:info@be-a.cz" class="link text-lg">info@be-a.cz</a></div>
					</div>
					<div class="col-span-3 text-right">
						<div><a href="https://www.instagram.com/amazing_be_a?igsh=eWt2czh5c2RoZXNz" target="_blank" class="link text-lg">Instagram</a></div>
						<div><a href="https://www.linkedin.com/in/filip-linek-a70292318/" target="_blank" class="link text-lg">Linkedin</a></div>
					</div>
				</div>
				<div class="md:hidden flex justify-between mb-s30">
					<div class="col-span-3">
						<p class="text-lg">Pujmanové 1753/10a,<br> 140 00 Praha 4-Nusle</p>
					</div>
					<div class="col-span-3 text-right">
					</div>
				</div>
				<div class="hidden md:flex justify-between items-center">
					<div class="col-span-3">
						<p class="text-xs">Copyright BE-A {{ new Date().getFullYear() }}</p>
					</div>
					<div class="col-span-3">
						<p class="text-xs">Member of <a href="https://flaerobotics.ai/" target="_blank" class="link is-underlined">FLÆ Robotics</a></p>
					</div>
					<div class="col-span-3 text-center">
						<a href="/pdf/privacy-policy.pdf" target="_blank" class="link text-xs is-underlined">Privacy policy</a>
					</div>
					<div class="col-span-3 text-right">
						<p class="text-xs">Created by <a href="https://brainzstudios.cz/" target="_blank" class="link is-underlined">Brainz Studios</a></p>
					</div>
				</div>
				<div class="md:hidden flex justify-between mb-5">
					<div class="col-span-3">
						<p class="text-xs">Copyright BE-A {{ new Date().getFullYear() }}</p>
					</div>
					<div class="col-span-3 text-center">
						<a href="/pdf/privacy-policy.pdf" target="_blank" class="link text-xs">Privacy policy</a>
					</div>
				</div>
				<div class="md:hidden flex justify-between">
					<div class="col-span-3">
						<p class="text-xs">Member of <a href="https://flaerobotics.ai/" target="_blank" class="link is-underlined">FLÆ Robotics</a></p>
					</div>
					<div class="col-span-3 text-right">
						<p class="text-xs">Created by <a href="https://brainzstudios.cz/" target="_blank" class="link is-underlined">Brainz Studios</a></p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>